.slider{
    
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.image-zoom{
    transform: scale(2, 2);
}

.slide:hover{
  cursor: url(/public/search.svg), auto;
}

.slide.active {
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.5);
}



.right-arrow {
    transform: scale(5);
    position: absolute;
    top: 40%;
    right: 32px;
    font-size: 0.3rem;
    color: #000;
    z-index: 10;
    cursor: pointer;
    user-select: none;
    padding: 0;
  }


.left-arrow {
    transform: scale(5);
    position: absolute;
    top: 40%;
    left: 32px;
    font-size: 0.3rem;
    color: #000;
    z-index: 10;
    cursor: pointer;
    user-select: none;
    padding: 0;
  }

  .back-btn {
    transform: scale(5);
    position: absolute;
    top: 5%;
    left: 32px;
    font-size: 0.3rem;
    color: #000;
    z-index: 10;
    cursor: pointer;
    user-select: none;
    padding: 0;
  }

  .info-btn{
    transform: scale(5);
    position: absolute;
    top: 75%; /* Moves button to the middle vertically */
    left: 50%; /* Moves button to the middle horizontally */
    padding: 10px 20px;
    font-size: 0.3rem;
    color: #000;
    z-index: 10;
    cursor: pointer;
    user-select: none;
    padding: 0;
  }


  @media screen and (min-width: 1024px) {
    .slider{
      margin-top: 30px;
    }

    .large-horizontal {
      margin-top: 200px;
      width: 420px;
      height: 300px;
    }

    .large-vertical {
      margin-top: 200px;
      width: 350px;
      height: 400px;
    }

    .landscape {
      margin-top: 200px;
      width: 500px;
      height: 300px;
    }

    .scroll {
      margin-top: 200px;
      width: 250px;
      height: 500px;
      transform: scale(80%);
    }

    .mnemosyne {
      margin-top: 200px;
      width: 320px;
      height: 450px;
    }


  }

  @media (min-width:500px) and (max-width: 960px){

    .large-horizontal{
      width: 720px;
      height: 600px;
      transform: scale(35%, 30%);
      margin-bottom: 250px;
    }

    .large-vertical{
      width: 550px;
      height: 600px;
      transform: scale(30%, 33%);
      margin-bottom: 250px;
    }

    .landscape{
      width: 700px;
      height: 600px;
      transform: scale(48%, 33%);
      margin-bottom: 250px;
    }

    .scroll{
      width: 550px;
      height: 600px;
      transform: scale(30%, 33%);
      margin-bottom: 250px;
      object-fit: cover;
      object-position: 50% 0;
      
    }

    .mnemosyne{
      width: 520px;
      height: 600px;
      transform: scale(30%, 33%);
      margin-bottom: 250px;
    }

    .left-arrow{
      display: none;
    }

    .right-arrow{
      display: none;
    }

  }
  
  @media screen and (max-width: 800px) {

    .large-horizontal{
      width: 720px;
      height: 600px;
      transform: scale(30%, 28%);
    }

    .large-vertical{
      width: 550px;
      height: 600px;
      transform: scale(38%, 40%);
    }

    .landscape{
      width: 800px;
      height: 600px;
      transform: scale(30%, 28%);
    }

    .scroll{
      margin-top: 100px;
      width: 500px;
      height: 1000px;
      transform: scale(32%);
    }

    .mnemosyne{
      width: 520px;
      height: 650px;
      transform: scale(38%, 40%);
    }
    

    .slider{
      height: auto;
    }

    .slide.active{
      margin-top: 200px;
    }

    .left-arrow{
      display: none;
    }

    .right-arrow{
      display: none;
    }
  }

  



  

  



 


